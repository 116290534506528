<template>
  <Connect @getContract="getContract"/>
  <div class="product">
    <van-nav-bar
        title="Explorer NFT"
        fixed
        placeholder
        left-arrow
        :border="false"
        @click-left="router.go(-1)"
    />

    <div class="main">
      <div class="title">
        <div>Please select the product</div>
        <div>Balance: <span>{{ nftBanner }}</span></div>
      </div>
      <div v-if="goods.length" class="list">
        <div v-for="(item,index) in goods" :key="index" :class="{ active: item.id === goodsId }" class="item" @click="handleSelect(item.id)">
          <img class="icon" :src="item.images" alt="">
          <div>{{ item.name }}</div>
        </div>
      </div>
      <div v-else class="noData">No data available</div>
      <div class="btnWrap">
        <div class="btn" @click="router.go(-1)">Cancel</div>
        <div class="btn" @click="handleConfirm">Confirm</div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import Connect from '@/components/Connect'
  import { useRouter, useRoute } from 'vue-router'
  import { ref } from 'vue'
  import { goodList } from '@/request/api'
  import { showToast } from 'vant'

  const route = useRoute()
  const router = useRouter()

  const nft_id = route.query.id
  const nftBanner = ref('')
  async function getContract(obj) {
    nftBanner.value = parseInt(await obj.blockNftContract.balanceOf(obj.address, nft_id))
  }

  // 确定
  function handleConfirm() {
    if(!goodsId.value) {
      showToast('Please select the product')
      return
    }
    router.push('/selectAddress?goods_id=' + goodsId.value)
  }

  const goodsId = ref('')
  function handleSelect(id) {
    goodsId.value = id
  }

  const goods = ref([])
  goodList({ nft_id }).then(res => {
    if(res.success) {
      goods.value = res.data
    }
  })
</script>

<style lang="less" scoped>
.product{
  .main{
    padding: 10px 38px 30px 38px;

    .title{
      color: #F8F8F8;
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 38px;

      span{
        color: #06C8A1;
      }
    }
    .list{
      .item{
        height: 48px;
        border-radius: 16px 10px 10px 16px;
        border: 2px solid #06C8A1;
        background: rgba(6, 200, 161, 0.10);
        padding-right: 26px;
        text-align: right;
        font-size: 16px;
        font-weight: 500;
        color: #F8F8F8;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        margin-bottom: 20px;

        .icon{
          width: 48px;
          height: 48px;
          border-radius: 0 16px 16px 0;
        }
      }
      .active{
        background: #06C8A1;
      }
    }
    .noData{
      font-size: 13px;
      text-align: center;
    }
    .btnWrap{
      display: flex;
      justify-content: space-between;
      padding: 30px;
      width: 100%;
      box-sizing: border-box;

      .btn{
        width: 92px;
        height: 36px;
        box-sizing: border-box;
        background: #697377;
        border-radius: 15px;
        border: 1px solid #ffffff;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .btn:hover{
        background: #06C8A1;
        border-color: #06C8A1;
      }
    }
  }
}
</style>
